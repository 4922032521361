class CorporationAddressSearch extends HTMLElement {
  connectedCallback() {
    const toggleButton = this.querySelector(".toggle-button")
    const toggleContent = this.querySelector(".toggle-content")
    const selectElement = this.querySelector(".c-form__select")
    const addressField = this.querySelector(".c-form__textfield")

    if (toggleButton && toggleContent) {
      toggleButton.addEventListener("click", (event) => {
        event.preventDefault()
        toggleContent.classList.toggle("hidden")
      })
    }

    if (selectElement) {
      window.addEventListener("load", function () {
        $(selectElement).select2({
          minimumInputLength: 2,
          width: "100%",
          ajax: {
            cache: true,
            delay: 300,
            url: "/kanri/corporations/search_address",
            dataType: "json",
            data: (params) => {
              return {
                q: params.term,
              }
            },
            processResults: (items) => {
              return {
                results: items.map((item) => {
                  return {
                    id: item.id,
                    text: `${item.name}<br>${item.address}`,
                  }
                }),
              }
            },
          },
          templateResult: function (data) {
            if (!data.id) {
              return data.text
            }
            return $(`<span>${data.text}</span>`)
          },
          templateSelection: function (data) {
            if (!data.id) {
              return data.text
            }
            return $(`<span>${data.text}</span>`)
          },
          escapeMarkup: function (markup) {
            return markup
          },
          language: {
            inputTooShort: function () {
              return `2文字以上入力してください。`
            },
            noResults: function () {
              return "該当するものが見つかりません。"
            },
          },
        })

        $(selectElement).on("select2:select", function (e) {
          const selectedText = e.params.data.text
          const address = selectedText.split("<br>")[1]
          if (addressField && address) {
            addressField.value = address.trim()
          }
        })
      })
    }
  }
}

customElements.define("corporation-address-search", CorporationAddressSearch)
