hasClass = function (target, className) {
  if (target.classList) {
    return target.classList.contains(className)
  } else {
    return new RegExp("(^| )" + className + "( |$)", "gi").test(
      target.className
    )
  }
}

isActiveCtl = function (target) {
  if (hasClass(target, "is-active")) {
    target.classList.remove("is-active")
  } else {
    target.classList.add("is-active")
  }
}

loadJsFile = function (file) {
  var start, tagjs
  tagjs = document.createElement("script")
  start = document.getElementsByTagName("script")[0]
  tagjs.async = true
  tagjs.src = file
  start.parentNode.insertBefore(tagjs, start)
}

getAjax = function (url, callback) {
  var xmlhttp
  xmlhttp = new XMLHttpRequest()
  xmlhttp.onreadystatechange = function () {
    if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
      return callback(xmlhttp)
    }
  }
  xmlhttp.open("GET", url, true)
  xmlhttp.send()
}

postAjax = function (url, callback, data, action) {
  var xmlhttp
  xmlhttp = new XMLHttpRequest()
  xmlhttp.open(action, url)
  xmlhttp.onreadystatechange = function () {
    if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
      return callback(xmlhttp)
    }
  }
  xmlhttp.setRequestHeader("X-CSRF-Token", getCsrfToken())
  xmlhttp.setRequestHeader("Content-Type", "application/json")
  xmlhttp.send(JSON.stringify(data))
}

getCsrfToken = () => {
  var elem, k, len, meta
  meta = document.getElementsByTagName("meta")
  for (k = 0, len = meta.length; k < len; k++) {
    elem = meta[k]
    if (elem.name === "csrf-token") {
      return elem.content
    }
  }
}

extend = () => {
  var i, key
  i = 1
  while (i < arguments.length) {
    for (key in arguments[i]) {
      if (arguments[i].hasOwnProperty(key)) {
        arguments[0][key] = arguments[i][key]
      }
    }
    i++
  }
  return arguments[0]
}

document.getElementById("js-flash")?.addEventListener("click", function (e) {
  if (hasClass(e.target, "js-alert")) {
    notificationRemove()
  }
})

notificationFlash = function (options) {
  var option, symbol, timeout, wrap
  option = options
  document.getElementsByClassName("c-flash__txt")[0].textContent =
    option.message
  wrap = document.getElementById("js-flash")
  if (option.type === "alert") {
    wrap.classList.add("js-alert")
    timeout = setTimeout("notificationRemove()", 8000)
  } else {
    wrap.classList.add("js-notice")
    timeout = setTimeout("notificationRemove()", 3000)
  }
}

notificationRemove = function () {
  var notification, symbol
  notification = document.getElementById("js-flash")
  if (
    hasClass(notification, "js-alert") ||
    hasClass(notification, "js-notice")
  ) {
    notification.classList.remove("js-alert", "js-notice")
    document.getElementsByClassName("c-flash__txt")[0].textContent = ""
  }
}

trueWidth = function (element) {
  var computedStyle, contentWidth
  computedStyle = getComputedStyle(element)
  contentWidth = element.clientWidth
  contentWidth -=
    parseFloat(computedStyle.paddingLeft) +
    parseFloat(computedStyle.paddingRight)
  return contentWidth
}

getTranslateX = function (obj) {
  var style, transform, xT
  style = obj.style
  transform = style.transform || style.webkitTransform || style.mozTransform
  xT = transform.match(
    /translate\([-]?([0-9]+(px|em|%|ex|ch|rem|vh|vw|vmin|vmax|mm|cm|in|pt|pc))\)/
  )
  if (xT) {
    return xT[1]
  } else {
    return "0"
  }
}

let _validFileExtensions = [".jpg", ".jpeg", ".gif", ".png", ".HEIC"]

validateSingleInput = function (oInput) {
  var blnValid, j, sCurExtension, sFileName
  if (oInput.type === "file") {
    sFileName = oInput.value
    if (sFileName.length > 0) {
      blnValid = false
      j = 0
      while (j < _validFileExtensions.length) {
        sCurExtension = _validFileExtensions[j]
        if (
          sFileName
            .substr(
              sFileName.length - sCurExtension.length,
              sCurExtension.length
            )
            .toLowerCase() === sCurExtension.toLowerCase()
        ) {
          blnValid = true
          break
        }
        j++
      }
      if (!blnValid) {
        alert(
          "このファイルは扱えません。次のファイルフォーマットが取り扱えます：" +
            _validFileExtensions.join(", ")
        )
        oInput.value = ""
        return false
      }
    }
  }
  return true
}

showErrorFromRails = (res, target) => {
  let html = res.body
  let keys = res.errors
  target.insertAdjacentHTML("afterbegin", html)
  keys.forEach((key) => {
    const elements = document.querySelectorAll(
      "[id^=" + key + "], label[for=" + key + "]"
    )
    elements.forEach((el) => {
      el.setAttribute("aria-invalid", "true")
      // el.outerHTML = '<span class="p-field-error">' + el.outerHTML + '</span>';
    })
  })
  target.scrollIntoView({ behavior: "smooth" })
}

clearRailsError = () => {
  const elements = document.querySelectorAll(".p-errors")
  elements.forEach((ele) => {
    ele.parentNode.removeChild(ele)
  })
  const inputs = document.querySelectorAll("[aria-invalid]")
  inputs.forEach((input) => {
    input.removeAttribute("aria-invalid")
  })
}

const unwrap = (target) => {
  while (target.firstChild) {
    target.parentNode.insertBefore(target.firstChild, target)
  }
  target.remove()
}

flashMessage = function (code) {
  switch (code) {
    case "alert__expired":
      return "有効期限が切れています"
    case "alert__invalid":
      return "コードが不正です"
    case "alert__invalid_token":
      return "ログインに失敗しました。ログインを再試行してください"
    case "alelrt__invalidemail":
      return "メールアドレスが正しくありません"
    case "alert__weakpassword":
      return "パスワードが簡単過ぎます"
    case "alert__verify":
      return "メールアドレスが認証できませんでした"
    case "alert__signup":
      return "ユーザー登録できません"
    case "alert__notsentverifyemail":
      return "認証メールが送れませんでした"
    case "alert__failedToSaveDraft":
      return "下書きを保存できませんでした"
    case "notice__notsentverifyemail":
      return "認証メールを送信しました"
    case "notice__signup":
      return "ユーザー登録ありがとうございます"
    case "notice__login":
      return "ログインしました"
    case "notice__passwordchange":
      return "パスワードを変更しました"
    case "notice__passwordsend":
      return "パスワードの再設定方法を数分以内にメールで連絡します"
    case "notice__verify":
      return "メールアドレスを認証しました"
    case "notice__alreadysignin":
      return "メールアドレス認証済みです"
    case "notice__saveDraft":
      return "下書きを保存しました"
    default:
      return "エラーです"
  }
}

let urlHash = window.location.hash
if ((flash = urlHash.match(/#js-flash--(([a-z]+)__([a-z]+))$/))) {
  message = flashMessage(`${flash[1]}`)
  notificationFlash({ message: message, type: flash[2] })
}
