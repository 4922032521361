// css
import "../src/stylesheets/application"

// rails
require("@rails/ujs").start()
require("@rails/activestorage").start()

// base libraries
require("jquery")
require("select2")
require("select2/dist/js/i18n/ja")

import "../src/javascripts/_fontawesome"

// common
require("../src/javascripts/_utility")
require("../src/javascripts/_element")

require.context("../images", true)
import "chartkick"
import "../src/javascripts/components"
