/// モーダル

document.addEventListener("DOMContentLoaded", () => {
  const modalOperators = Array.from(
    document.querySelectorAll('[data-toggle="modal"]')
  )
  modalOperators.forEach((operator) => {
    const modalSelector = operator.getAttribute("data-target")
    if (!modalSelector) {
      return
    }

    const modal = document.querySelector(modalSelector)
    if (!modal) {
      return
    }

    const modalContent = document.querySelector(`${modalSelector} .c-modal`)
    modalContent.addEventListener("click", (e) => {
      e.stopPropagation()
    })

    const closeButtons = Array.from(
      document.querySelectorAll(
        `${modalSelector} [data-dismiss="modal"], .c-modal--wrapper`
      )
    )
    closeButtons.forEach((closeButton) => {
      closeButton.addEventListener("click", (e) => {
        e.preventDefault()
        const cancelled = !modal.dispatchEvent(
          new Event("hide.om.modal", { cancelable: true })
        )
        if (cancelled) {
          return
        }
        const isOpenedSingleModal =
          document.querySelectorAll(".c-modal--wrapper.is-opened").length === 1
        // モバイル端末対策 (モーダル下にある要素のスクロール有効化＆スクロール位置復元)
        if (
          window.matchMedia("(max-width: 1000px)").matches &&
          isOpenedSingleModal
        ) {
          const topOffset = document.body.style.top
          document.body.style.width = ""
          document.body.style.position = ""
          document.body.style.top = ""
          window.scrollTo(0, parseInt(topOffset || "0") * -1)
        }
        modal.classList.remove("is-opened")
        if (isOpenedSingleModal)
          document.body.classList.remove("c-modal--scroll-block")
        modal.dispatchEvent(new Event("hidden.om.modal", { cancelable: false }))
      })
    })

    operator.addEventListener("click", (e) => {
      e.preventDefault()
      const canceleed = !modal.dispatchEvent(
        new Event("show.om.modal", { cancelable: true })
      )
      if (canceleed) {
        return
      }
      modal.classList.add("is-opened")
      modal.scrollTop = 0
      const isOpenedSingleModal =
        document.querySelectorAll(".c-modal--wrapper.is-opened").length === 1
      if (isOpenedSingleModal)
        document.body.classList.add("c-modal--scroll-block")
      // モバイル端末対策 (モーダル下にある要素のスクロール無効化＆スクロール位置保持)
      if (
        window.matchMedia("(max-width: 1000px)").matches &&
        isOpenedSingleModal
      ) {
        document.body.style.top = `-${window.scrollY}px`
        document.body.style.position = "fixed"
        document.body.style.width = "100%"
      }
      modal.dispatchEvent(new Event("shown.om.modal", { cancelable: false }))
    })
  })
})

/// data-toggle

document.addEventListener("DOMContentLoaded", () => {
  const tabs = Array.from(document.querySelectorAll('[data-toggle="tab"]'))
  tabs.forEach((tab) => {
    const target = tab.getAttribute("data-target")
    const tabContent = document.querySelector(target)
    if (!tabContent) {
      return
    }
    const tabParent = tab.closest(".c-tab__tabs")
    const tabContentParent = tabContent.parentNode
    tab.addEventListener("click", () => {
      tabParent.querySelector(".is-active")?.classList.remove("is-active")
      tab.classList.add("is-active")
      tabContentParent
        .querySelector(".is-active")
        ?.classList.remove("is-active")
      tabContent.classList.add("is-active")
    })
  })
})

document.addEventListener("DOMContentLoaded", () => {
  const collapses = Array.from(
    document.querySelectorAll('[data-toggle="collapse"]')
  )
  collapses.forEach((collapse) => {
    const target = collapse.getAttribute("data-target")
    const collapseBody = document.querySelector(target)
    if (!collapseBody) {
      return
    }
    collapse.addEventListener("click", (e) => {
      // checkboxやradioのclickイベントだった場合はスキップする
      if (
        ["input", "label"].includes((e.target?.tagName || "").toLowerCase())
      ) {
        return
      }
      const isCollapsed = collapse.classList.contains("collapsed")
      if (isCollapsed) {
        collapse.classList.remove("collapsed")
        collapseBody.classList.add("show")
      } else {
        collapse.classList.add("collapsed")
        collapseBody.classList.remove("show")
      }
    })
  })
})

document.addEventListener("DOMContentLoaded", () => {
  const checkboxes = Array.from(
    document.querySelectorAll('[data-toggle="check-all"]')
  )
  checkboxes.forEach((checkbox) => {
    const target = checkbox.getAttribute("data-target")
    const targetCheckboxes = Array.from(document.querySelectorAll(target))
    if (targetCheckboxes.length === 0) {
      return
    }
    checkbox.addEventListener("change", () => {
      const isChecked = checkbox.checked
      targetCheckboxes.forEach((targetCheckbox) => {
        targetCheckbox.checked = isChecked
        targetCheckbox.disabled = isChecked
      })
    })

    targetCheckboxes.forEach((targetCheckbox) => {
      targetCheckbox.addEventListener("change", () => {
        const checkedCount = Array.from(
          document.querySelectorAll(target)
        ).filter((elm) => elm.checked).length
        if (targetCheckboxes.length === checkedCount) {
          checkbox.indeterminate = false
          checkbox.checked = true
          checkbox.dispatchEvent(new Event("change"))
        } else if (checkedCount === 0) {
          checkbox.indeterminate = false
          checkbox.checked = false
          checkbox.dispatchEvent(new Event("change"))
        } else {
          checkbox.indeterminate = true
        }
      })
    })
  })
})

/// アコーディオン

document.addEventListener("DOMContentLoaded", () => {
  const elements = Array.from(document.querySelectorAll(".js-accordion"))
  if (elements.length === 0) return

  const switchDisplay = (obj) => {
    const target = obj.target
    const accordion_content_id = target.dataset.accordion
    const accordion_content = document.getElementById(accordion_content_id)
    const accordion_icon_up = document.getElementById(
      accordion_content_id + "-icon-up"
    )
    const accordion_icon_down = document.getElementById(
      accordion_content_id + "-icon-down"
    )
    if (accordion_content.classList.contains("u-dpn")) {
      accordion_content.classList.remove("u-dpn")
      target.textContent = target.dataset.textReverse
      accordion_icon_up.classList.remove("u-dpn")
      accordion_icon_down.classList.add("u-dpn")
    } else {
      accordion_content.classList.add("u-dpn")
      target.textContent = target.dataset.textNormal
      accordion_icon_up.classList.add("u-dpn")
      accordion_icon_down.classList.remove("u-dpn")
    }
  }

  elements.forEach((element) => {
    element.addEventListener("click", switchDisplay)
    element.textContent = element.dataset.textNormal
  })
})
